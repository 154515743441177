@import url('../../styles/vars.css');

.ResultsOverview {
  margin-top: 30px;
}

.flexContainerTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overviewText {
  margin-top: 64px;
}

.overviewText h2 {
  color: var(--color-text-softBlack);
  font-size: 3.6rem;
  line-height: 3.8rem;
  margin-left: 10px;
}

.overviewText p {
  color: var(--color-text-gray500);
  text-align: center;
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 16px;
}

.hr {
  display: none;
}

@media screen and (min-width: 768px) {
  .flexContainerTop {
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 35px;
  }

  .overviewText {
    align-self: stretch;
    margin-top: 0;
    margin-right: 24px;
    max-width: 426px;
  }

  .overviewText h2 {
    margin-left: 0;
  }

  .overviewText p {
    text-align: left;
  }

  .ReminderLabel {
    margin-top: 16px;
    background-color: var(--color-overview-yellow);
    color: var(--color-overview-text-lightBrown);
    padding: 16px;
    border-radius: 16px;
    font-size: 2rem;
    font-weight: 500;
    max-width: 426px
  }

  .ReminderLabel .bold {
    font-weight: 700;
  }
}

@media screen and (min-width: 992px) {
  .ResultsOverview {
    margin-top: 70px;
  }

  .hr {
    display:block;
    width: 100vw;
    border: 1px solid #DCDCDC;
    margin-top: 50px;
  }
}

@media screen and (min-width: 1440px) {
  .flexContainerTop {
    padding-right: 60px;
  }

  .overviewText {
    max-width: 626px;
  }
}