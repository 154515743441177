@import url('../../styles/vars.css');

.headerContainer {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  height: 104px;
  width: 104px;
}

.header {
  margin-top: 8px;
  text-align: center;
  font-size: 3.6rem;
  line-height: 4.4rem;
  color: var(--color-text-gray900);
}

.subheader {
  margin-top: 20px;
  text-align: center;
  font-family: 'Poppins';
  font-size: 2rem;
  line-height: 3rem;
  color: var(--color-text-gray500);
}

.illustration {
  margin-top: 40px;
  height: 169px;
}

@media screen and (min-width: 768px) {
  .headerContainer {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .iconContainer {
    width: 100%;
  }

  .header, .subheader {
    text-align: left;
    max-width: 475px;
  }

  .header, .illustration {
    margin-top: unset;
  }

  .subheader {
    margin-top: 8px;
  }
}

@media screen and (min-width: 992px) {
  .headerContainer {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 60px;
  }

  .iconContainer {
    width: auto;
  }

  .icon {
    height: 160px;
    width: 160px;
  }

  .header, .subheader {
    max-width: 475px;
  }
}

@media screen and (min-width: 1440px) {
  .headerContainer {
    padding: 0 30px;
    align-items: center;
  }

  .illustration {
    height: 238px;
  }
}

@media print {
  .headerContainer {
    display: none;
  }
}