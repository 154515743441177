@import url('../../styles/vars.css');

.flexContainerCards {
  margin-top: 69px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  margin-top: 19px;
  background: #FFFFFF;
  border: 1px solid var(--color-border-gray);
  border-radius: 16px;
}

.card:first-of-type {
  margin-top: 0px;
}

.card h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  margin-top: 25px;
  text-align: center;
}

.card p {
  color: var(--color-text-gray500);
  text-align: center;
  margin-top: 25px;
}

.archetypeHeader {
  color: var(--color-archetype-text-lightPurple);
}

.innovationHeader {
  color: var(--color-innovation-text-lightBlue);
}

.cohesionHeader {
  color: var(--color-cohesion-darkBlueGreen);
}

@media screen and (min-width: 768px) {
  .flexContainerCards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 43px;
  }

  .card {
    max-height: 330px;
    margin-top: 0;
    padding: 47px 24px;
  }

  .card:not(:last-of-type) {
    width: calc(50% - 12px);
  }

  .card:first-of-type {
    margin-right: 24px;
  }

  .card img {
    height: 121px;
  }

  .card h3 {
    margin-top: 8px;
  }

  .card p {
    margin-top: 8px;
  }

  /* Last card styles */
  .card:last-of-type {
    width: 100%;
    margin-top: 17px;
    max-height: 223px;
    flex-direction: row;
    padding: 52px 36px;
  }

  .card:last-of-type img {
    margin-right: 36px;
  }

  .card:last-of-type h3, .card:last-of-type p {
    text-align: left;
  }

  .card:last-of-type h3 {
    margin-top: 0;
  }

  .card:last-of-type p {
    width: 415px;
  }
  
}

@media screen and (min-width: 992px) {
  .flexContainerCards {
    flex-wrap: nowrap;
    margin-top: 211px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  .card, .card:last-of-type, .card:not(:last-of-type) {
    position: relative;
    height: 263px;
    max-height: 263px;
    width: 287px;
    padding: 0 24px;
    margin-top: 0;
    margin-right: 0;
    flex-direction: column;
  }

  .card:last-of-type {
    padding: 0 14.5px;
  }

  .card img, .card:last-of-type img {
    position: absolute;
    height: 160px;
    margin: 0;
    top: -122px;
  }

  .card h3, .card:last-of-type h3 {
    margin-top: 55px;
  }
  

  .card:last-of-type h3, .card:last-of-type p {
    text-align: center;
  }

  .card:last-of-type p {
    width: auto;
  }
}

@media screen and (min-width: 1440px) {
  .flexContainerCards {
    margin-top: 211px;
    max-width: none;
    padding: 0 24px;
  }

  .card, .card:last-of-type, .card:not(:last-of-type) {
    height: 222px;
    max-height: 222px;
    width: 349px;
    padding: 0 16.5px;
  }
}