@import url('../../styles/vars.css');

.SuccessRates {
  margin-top: 28px;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  height: 193px;
}

.header, .subheader {
  text-align: center;
}

.header {
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 5.4rem;
  letter-spacing: -0.02em;
  color: var(--color-text-charcoal);

  margin-top: 54px;
}

.subheader {
  font-size: 2rem;
  line-height: 3.6rem;
  color: var(--color-text-gray500);

  margin-top: 17px;
}

@media screen and (min-width: 768px) {
  .headerContainer {
    position: relative;
  }

  .icon {
    position: absolute;
    right: -30px;
  }

  .header, .subheader {
    text-align: left;
  }

  .header {
    margin-top: 40px;
    max-width: calc(100% - 200px);
  }

  .subheader {
    margin-top: 25px;
    max-width: calc(100% - 60px);
  }
}

@media screen and (min-width: 992px) {
  .icon {
    height: 280px;
    right: -20px;
  }

  .header {
    margin-top: 15px;
    max-width: calc(100% - 360px);
  }

  .subheader {
    margin-top: 25px;
    max-width: calc(100% - 305px);
  }
}

@media screen and (min-width: 1440px) {
  .icon {
    right: 0px;
  }

  .header {
    margin-top: 25px;
    max-width: calc(100% - 360px);
  }

  .subheader {
    margin-top: 24px;
    max-width: calc(100% - 330px);
  }
}

@media print {
  .SuccessRates {
    display: none;
  }
}