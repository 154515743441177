@import url('../../styles/vars.css');

.Dom_SubDomCards {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 51.172px 25.586px;
  margin-top: 15px;
  gap: 12.79px;
  background: #FFFFFF;
  border: 0.719606px solid var(--color-border-gray);
  border-radius: 6.3965px;
}

.card:first-of-type {
  margin-top: 65px;
}

.header {
  font-size: 20px;
  line-height: 19px;
  color: var(--color-text-black);
}

.body {
  color: var(--color-text-gray500);
  font-family: 'Poppins', sans-serif;
  margin-top: 12.79px;
  line-height: 26px;
}

.bold {
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .Dom_SubDomCards {
    margin-top: 73px;
    flex-direction: row;
    justify-content: center;
  }

  .card, .card:first-of-type {
    margin-top: 0px;
  }

  .card {
    width: 358px;
    background-color: var(--color-overview-yellow);
    margin-right: 14px;
  }

  .body {
    color: var(--color-text-black);
  }
}

@media screen and (min-width: 992px) {
  .Dom_SubDomCards {
    margin-top: 104px;
    gap: 17px;
  }

  .card {
    width: auto;
    background-color: #FFF;
    margin-right: 14px;
  }

  .header {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }

  .body {
    color: var(--color-text-black);
    font-size: 2rem;
    line-height: 3.2rem;
  }
}