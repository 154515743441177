:root {
  --color-text-black: rgb(40, 40, 40);
  --color-text-softBlack: rgb(51, 51, 51);
  --color-text-charcoal: rgb(65, 65, 65);
  --color-text-gray500: rgb(102, 112, 133);
  --color-text-gray700: rgb(52, 64, 84);
  --color-text-gray900: rgb(12, 24, 40);
  --color-text-eggshell: rgb(241, 225, 225);
  --color-border-gray: rgb(232, 232, 232);
  --color-charcoal-black-5: rgb(246, 246, 246);
  --color-overview-yellow: rgb(252, 232, 176);
  --color-overview-text-lightBrown: rgb(108, 84, 18);
  --color-overview-text-brown: rgb(112, 82, 0);
  --color-archetype-lavender: rgb(211, 180, 237);
  --color-text-lavender: rgb(241, 225, 255);
  --color-archetype-text-lightPurple: rgb(146, 70, 206);
  --color-archetype-text-purple: rgb(95, 23, 155);
  --color-archetype-purple: rgb(81, 21, 129);
  --color-innovation-superlite: #C2DEFF;
  --color-innovation-skyBlue: rgb(165, 204, 255);
  --color-innovation-text-lightBlue: rgb(56, 138,246);
  --color-innovation-text-blue: rgb(0, 77, 168);
  --color-innovation-text-darkBlue: rgb(0, 57, 132);
  --color-cohesion-blueGreen: rgb(117, 207, 209);
  --color-cohesion-cyan: rgb(112, 232, 220);
  --color-cohesion-darkCyan: rgb(58, 204, 190);
  --color-cohesion-darkBlueGreen: rgb(43, 155, 144);
  --color-red: rgb(237, 40, 42);
}