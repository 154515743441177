@import url('../../styles/vars.css');

.AblohQuoteCard {
  background-color: #1C1C1C;
  color: #FFF;
  padding: 24px 31.5px;
  margin-top: 33px;
  border-radius: 8px;
  font-style: italic;
}

.source {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .AblohQuoteCard {
    margin-right: 28px;
    padding: 58px 16px;
    flex: 1;
  }
}

@media screen and (min-width: 992px) {
  .AblohQuoteCard {
    margin-right: 34px;
  }
}

@media screen and (min-width: 1440px) {
  .AblohQuoteCard {
    position: absolute;
    left: 0;
    top: 370px;
    width: 728px;

    display: flex;
    align-items: center;
    padding: 37px;
  }

  .quote {
    font-size: 21px;
    line-height: 31px;
  }
}