@import url('../../styles/vars.css');

.CCC_Style_Card {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.CCC_Style_Card:not(:first-of-type) {
  margin-top: 16px;
}

.cardSection {
  display: flex;
  gap: 10px;

  background-color: white;
  border: 0.777251px solid var(--color-border-gray);
  border-radius: 6.21801px;
  padding: 12.436px;
}

.subsections {
  display: flex;
  flex-direction: column;
  gap: 8px;

  /* Transition properties */
  -webkit-transition: max-height .75s; 
  -moz-transition: max-height .75s; 
  -ms-transition: max-height .75s; 
  -o-transition: max-height .75s; 
  transition: max-height .75s; 
  overflow: hidden;
  max-height: 0;
}

.active {
  max-height: 800px;

  margin-bottom: 8px;
}

.head {
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 18.654px 18.654px 18.654px 16px;
  cursor: pointer;
}

.headIcon {
  height: 48px;
  width: 48px;
}

.head h5 {
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: var(--color-text-gray500);

  flex: 1;
}

.caret {
  width: 35px;
  transition: transform .25s;
}

.rotate180 {
  transform: rotate(180deg);
}

.featureIcon {
  height: 30px;
  width: 30px;
}

.cardSection h6 {
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: var(--color-text-gray700);

  margin-top: 5px;
  margin-bottom: 10px;
}

.cardSection p, .cardSection li {
  font-size: 1.2rem;
  line-height: 2rem;
  color: var(--color-text-gray500);
}

.list {
  padding-left: 16px;
}

.cardSection ul {
  font-family: 'Inter';
  margin-top: 8px;
  margin-bottom: 0px;
}

.waysToGetBetter h6 {
  font-weight: 500;
  line-height: 2rem;
}

.waysToGetBetter li {
  line-height: 2.4rem;
}

@media screen and (min-width: 768px) {
  .CCC_Style_Card, .subsections {
    gap: 12px;
  }

  .subsections {
    max-height: unset;
  }

  .CCC_Style_Card:not(:first-of-type) {
    margin-top: 0;
  }

  .head {
    padding: 18.654px 5px;
    cursor: default;
    gap: 8px;
  }

  .cardSection {
    padding: 10px;
  }
  
}

@media screen and (min-width: 992px) {
  .head {
    padding: 19px;
    gap: 12px;
    justify-content: center;
  }

  .head h5 {
    flex: unset;
  }

  .description, .strengths {
    height: 215px;
  }

  .cardSection p, .cardSection li {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

@media screen and (min-width: 1440px) {
  .headIcon {
    height: 62px;
    width: 62px;
  }

  .head h5 {
    font-size: 1.8rem;
    line-height: 3rem;
  }

  .cardSection {
    padding: 16px;
  }

  .featureIcon {
    height: 37.5px;
    width: 37.5px;
  }

  .cardSection h6 {
    font-size: 1.8rem;
    margin-top: 8px;
  }

  .cardSection p, .cardSection li {
    font-size: 1.5rem;
  }
}

@media print {
  .subsections {
    max-height: 800px;
  
    margin-bottom: 8px;
  }
}

@media print {
  .CCC_Style_Card {
    page-break-inside: avoid;
  }
}