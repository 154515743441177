@import url('../../styles/vars.css');

.CharacteristicsResults > h2 {
  text-align: center;
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 5.4rem;
  color: var(--color-text-black)
}

@media screen and (min-width: 768px) {
  .CharacteristicsResults > h2 {
    margin: 0 60px;
  }

  .CharacteristicsResults > p {
    margin: 24px 85px 0;
    font-family: 'Poppins';
    font-size: 2rem;
    line-height: 2.7rem;
    text-align: center;
    color: var(--color-text-gray500)
  }
}

@media screen and (min-width: 992px) {
  .CharacteristicsResults > h2 {
    margin: 0 132px;
  }

  .CharacteristicsResults > p {
    margin-left: 132px;
    margin-right: 132px;
    line-height: 3.2rem;
  }
}

@media screen and (min-width: 1440px) {
  .CharacteristicsResults {
    padding-top: 60px;
  }

  .CharacteristicsResults > h2 {
    margin: 0 auto;
    max-width: 966px;
  }

  .CharacteristicsResults > p {
    margin: 40px auto 0;
    line-height: 3.2rem;
    max-width: 1095px;
  }
}

@media print {
  .CharacteristicsResults {
    page-break-before: always;
  }
}