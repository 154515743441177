@import url('../../../styles/vars.css');

.CircularProgressbar {
  height: 190px;
  width: 190px;
}

.CircularProgressbar .CircularProgressbar-path,
.CircularProgressbar .CircularProgressbar-trail {
  stroke-width: 8.5px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: var(--color-cohesion-blueGreen);
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: var(--color-charcoal-black-5);
}

@media screen and (min-width: 992px) {
  .CircularProgressbar {
    height: 180px;
    width: 180px;
  }
}