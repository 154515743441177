@import url('../../styles/vars.css');

.ArchetypeCard {
  background: #FFFFFF;
  border: 1px solid var(--color-border-gray);
  border-radius: 8px;
  min-width: 316px;
  width: 50%;
  padding-top: 32px;
  padding-bottom: 9px;
  margin-right: 12px;
  margin-top: 48px;
}

.topRow {
  display: flex;
  justify-content: space-between;
}

.icon {
  margin-left: 9px;
}

.scoreContainer {
  width: 137px;
  max-height: 67px;
  border-radius: 100px 0 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Dominant .scoreContainer {
  background-color: var(--color-archetype-purple);
}

.Subdominant .scoreContainer {
  background-color: var(--color-archetype-lavender);
}

.Dominant .scoreContainer span {
  color: var(--color-text-lavender);
}

.Subdominant .scoreContainer span {
  color: var(--color-archetype-text-purple);
}

.scoreContainer span:first-child {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.7rem;
}

.score {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.9rem;
}

.header h5 {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 16px;
  
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.9rem;
}

.Dominant .header h5 {
  background-color: var(--color-archetype-purple);
  color: var(--color-text-lavender);
}

.Subdominant .header h5 {
  background-color: var(--color-archetype-lavender);
  color: var(--color-archetype-text-purple);
}

.header h2 {
  margin-top: 6px;

  color: var(--color-text-black);
  font-weight: 700;
  font-size: 4.0rem;
  line-height: 6.0rem;
}

.body {
  margin: 21px 25px 0;
}

.subHeader {
  margin-top: 6px;

  font-family: 'Poppins';
  font-weight: 500;
  font-size: 2.1rem;
  line-height: 3.2rem;
}

.description {
  margin-top: 14px;

  color: var(--color-text-charcoal);
  font-family: 'Poppins';
  font-size: 1.6rem;
  line-height: 2.8rem;
}

@media screen and (min-width: 768px) {
  .ArchetypeCard {
    width: 100%;
    margin-right: 0;
    margin-top: 30px;
    height: auto;
  }

  .icon {
    margin-left: 25px;
    margin-right: 15px;
  }

  .header {
    margin-right: auto;
  }
  .subHeader {
    margin-top: 30px;
  }

  .description {
    margin-top: 24px;
  }
}

@media screen and (min-width: 992px) {
  .ArchetypeCard {
    padding-bottom: 12px;
  }
}

@media screen and (min-width: 1440px) {
  .ArchetypeCard {
    padding-bottom: 12px;
  }
}