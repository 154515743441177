.overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 76px;
}

.hr {
  display: none;
}

@media screen and (min-width: 768px) {
  .hr {
    display: block;
    width: 100%;
    border: 1px solid #DCDCDC;
    margin: 38px 0 0;
  }
}

@media screen and (min-width: 992px) {
  .hr {
    margin-top: 100px;
  }
}

@media print {
  .overview {
    display: none;
  }
}