@import '../../styles/vars.css';

/* General */

footer {
  background: #000000;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 50px;
  margin-top: 100px;
}

footer ul {
  margin: 0;
}

.logoContainer img {
  width: 325px;
  height: 100px;
}

/* Footer Items */
.footerItems {
  padding-left: 0;
}

.footerItem {
  height: 7rem;
  display: flex;
  align-items: center;
}

.footerItems a {
  color: #ffffff;
  font-size: 3rem;
  text-decoration: none;
}
.footerItemActive a {
  display: flex;
  align-items: flex-start;
  color: #EB4F28;
}
.active.footerItem a {
  color: red;
}
.superText {
  font-size: 1rem;
}
.socialMedia {
  display: flex;
  gap: 40px;
  padding-left: 0;
}

@media screen and (min-width: 768px) {
  footer {
    flex-direction: row;
  }
  .logoContainer {
    flex: 50%;
  }
  .footerLinks {
    flex: 50%;
    padding-left: 50px;
  }
}

@media screen and (min-width: 992px) {
  footer {
    flex-wrap: nowrap;
  }
  .logoContainer {
    flex: 25%;
  }
  .footerLinks {
    flex: 40%;

  }
}

@media screen and (min-width: 1440px) {
  footer {
    padding: 90px 50px;
  }
  .logoContainer {
    flex: 33%;
  }
  .footerLinks {
    flex: 33%;
  }
  .footerItems a {
    font-size: 4rem;
    line-height: 8rem;
  }
}

@media print {
  footer {
    display: none;
  }
}