@import url('../../styles/vars.css');

.errorContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.errorIllustration, .errorTextContainer {
  max-width: 80%;
}

.errorTextContainer h2 {
  margin-bottom: 10px;

  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4.4rem;
  color: var(--color-text-black);
}

.errorTextContainer p {
  margin-bottom: 10px;
  font-family: 'Poppins';
  font-size: 2rem;
  line-height: 3rem;
  color: var(--color-text-gray500);
}

.errorLink {
  color: var(--color-red);
  font-weight: 600;
  text-decoration: none;
}

@media screen and (min-width: 992px) {
  .errorContainer {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  
    height: 100vh;
    width: 100vw;
  }

  .errorIllustration {
    width: 42%;
    max-width: 630px;
  }

  .errorTextContainer {
    max-width: 435px;
  }
}