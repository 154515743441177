@import url('../../styles/vars.css');

.SkillsTalentsRank {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

.header {
  margin-top: 20px;
  font-size: 3.6rem;
  line-height: 5.4rem;
  color: var(--color-text-softBlack);
}

.body {
  margin-top: 18px;
  font-size: 2rem;
  line-height: 3.4rem;
  color: var(--color-text-gray500);
}

.body * {
  font-family: 'Poppins', sans-serif;
}

@media screen and (min-width: 768px) {
  .SkillsTalentsRank {
    position: relative;
    margin-top: 87px;
    flex-direction: row;
    align-items: center;
  }

  .SkillsTalentsRank img {
    position: absolute;
    right: 32px;
    top: 70px;
  }
  
  .header {
    margin-top: 0;
    text-align: left;
    max-width: 493px;
  }

  .body {
    margin-top: 22px;
    max-width: 422px;
    text-align: left;
  }
}

@media screen and (min-width: 992px) {
  .SkillsTalentsRank {
    margin-top: 52px;
    padding-right: 0;
    flex-direction: row;
    justify-content: space-between;
  }

  .SkillsTalentsRank img {
    position: static;
    width: 350px;
  }

  .textContent {
    width: 55%;
  }
  
  .header {
    max-width: none;
  }

  .body {
    max-width: none;
    text-align: left;
  }
  
  .bold {
    font-weight: 700 !important;
  }
}