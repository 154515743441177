.header {
  margin-top: 40px;
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 3.6rem;
  line-height: 5.4rem;
  font-weight: 600;
  text-align: center;
  color: var(--color-text-softBlack);
}

.arrowContainer {
  display: flex;
  justify-content: space-between;
  margin: 19px auto 0;
  width: 160px;
}

.carouselArrowReverse {
  transform: rotate(180deg);
}

@media screen and (min-width: 768px) {
  .header {
    width: 600px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 992px) {
  .EntreIntraPairCards {
    margin-bottom: 30px;
  }

  .header {
    width: unset;
  }
}

@media print {
  .EntreIntraPairCards, .header {
    display: none;
  }
}