@import url('../../styles/vars.css');

.EntreIntraPairCard {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.innerCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48.5%;
  height: 272.01px;
  background: #FFFFFF;
  border: 0.480756px solid var(--color-border-gray);
  border-radius: 3.84605px;
  padding: 10px 8.17px;
}

.cardImage {
  width: 105px;
  height: 105px;
}

.label {
  display: inline-block;
  background-color: var(--color-innovation-skyBlue);
  padding: 3px 5px;
  border-radius: 7.6921px;
  margin-top: 8.5px;
}

.label > p {
  color: var(--color-innovation-text-darkBlue);
  font-family: 'Poppins';
  font-weight: 500;
  font-size: .9rem;
  line-height: 1rem;
}

.innerCard > h4 {
  color: var(--color-text-black);
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1.63072rem;
  line-height: 2.1rem;
  margin-top: 6.5px;
}

.innerCard > p {
  color: var(--color-text-gray500);
  font-size: .9rem;
  line-height: 1.3rem;
  text-align: center;
  margin-top: 6.5px;
}

.dottedLine {
  position: absolute;
  width: 16.3%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-top: 5px dotted rgb(208, 208, 216);
}

@media screen and (min-width: 768px) {
  .innerCard {
    height: 545px;
    border-radius: 8px;
    padding: 22px 16px;
  }

  .cardImage {
    width: 219px;
    height: 219px;
  }

  .label {
    padding: 2px 8px;
    border-radius: 16px;
    margin-top: 30px;
  }

  .label > p {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .innerCard > h4 {
    color: var(--color-text-gray900);
    font-size: 3.6rem;
    line-height: 4.4rem;
    margin-top: 12px;
  }

  .innerCard > p {
    font-size: 1.6rem;
    line-height: 3rem;
    margin-top: 12px;
  }
}

@media screen and (min-width: 992px) {
  .innerCard {
    height: 525px;
    border-radius: 8px;
    padding: 24px 16px;
  }

  .dottedLine {
    width: 152px;
  } 
}