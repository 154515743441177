@import url('../../styles/vars.css');

.WaysToGetBetterCard {
  background: #FFFFFF;
  border: 1px solid var(--color-border-gray);
  border-radius: 8px;
  padding: 36px 75px 32px 32px;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.header {
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 5.4rem;
  letter-spacing: -0.02em;
  color: var(--color-text-black);
}

.Tip {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.tipIcon {
  margin-right: 16px;
}

.title {
  color: var(--color-text-charcoal);
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.5rem;
}

.text {
  font-family: 'Poppins';
  color: var(--color-text-charcoal);
  font-size: 1.6rem;
  line-height: 2.5rem;
}

.list {
  padding-left: 16px;
}

.hr {
  border: 1px solid var(--color-border-gray);
  margin: 15px 0;
}

@media print {
  .WaysToGetBetterCard {
    page-break-before: always;
    page-break-after: always;
  }
}