@import url('../../styles/vars.css');

.NowWhat {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.textContainer {
  margin-top: 58px;
}

.subheader {
  font-family: 'Inter';
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgb(237, 40, 42);
}

.header {
  font-family: 'Inter';
  font-size: 3.6rem;
  line-height: 4.4rem;
  letter-spacing: -0.02em;
  color: var(--color-text-gray900);

  margin-top: 12px;
  padding-right: 20px;
  max-width: 594px;
}

.paragraphContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 676px;
}

.paragraph {
  font-size: 2rem;
  line-height: 3rem;
  color: var(--color-text-gray500);
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.buttonContainer button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  width: 184px;
  height: 48px;
  
  border-radius: 8px;

  font-family: 'Inter';
  font-weight: 500;
  line-height: 2.4rem;

  cursor: pointer;
}

.shareButton {
  background-color: white;
  border: 1px solid #D0D5DD;
  color: var(--color-text-gray700);
}

.downloadButton {
  background-color: var(--color-red);
  border: 1px solid var(--color-red);
  color: white;
}

.mobileDownloadText {
  color: var(--color-red);
}

@media screen and (min-width: 768px) {
  .textContainer {
    margin-top: unset;
  }

  .buttonContainer {
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
}

@media screen and (min-width: 992px) {
  .paragraphContainer {
    max-width: unset;
  }
}

@media screen and (min-width: 1440px) {
  .NowWhat {
    flex-direction: row-reverse;
    gap: 60px;
  }

  .illustration,
  .contentContainer {
    flex: 1;
  }
}

@media print {
  .NowWhat {
    display: none;
  }
}