@import url('../../styles/vars.css');

.CCC_Cards {
  margin-top: 48px;
}

.bold {
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  margin-top: 41px;
}

.grayText {
  color: var(--color-text-gray500);
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  margin-top: 32px;
}

@media screen and (min-width: 768px) {
  .cardContainer {
    display: flex;
    gap: 10px;

    position: relative;
    margin-top: 32px;
  }

  .linePattern {
    position: absolute;
    transform: rotate(-90deg);
    z-index: -2;
  }

  .linePattern1 {
    top: -78px;
    right: -129px;
  }

  .linePattern2 {
    width: 298px;
    bottom: -38px;
    left: -157px;
  }

  .polygon {
    position: absolute;
    z-index: -1;
  }

  .polygon1 {
    top: 28px;
    right: -72px;
  }

  .polygon2 {
    bottom: 62.35px;
    left: -27px;
  }
}

@media screen and (min-width: 992px) {
  .cardContainer {
    gap: 30px;

    margin-top: 86px;
  }

  .linePattern1 {
    right: -100px;
  }

  .linePattern2 {
    bottom: -78px;
    left: -157px;
  }
}

@media screen and (min-width: 1440px) {
  .linePattern1 {
    right: -100px;
    top: -160px;
  }

  .linePattern2 {
    left: -107px;
  }

  .polygon1 {
    top: -60px;
    right: -72px;
    transform: rotate(90deg);
  }

  .polygon2 {
    left: -60px;
    transform: rotate(180deg);
  }
}