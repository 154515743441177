@import '../../styles/vars.css';

/* General */
.strong {
  font-weight: 700;
}
.italic {
  font-style: italic;
}
.innovationMindset {
  margin-top: 148px;
}
.title {
  font-size: 4rem;
  line-height: 5.5rem;
}
.subtitle {
  border-radius: 16px;
  padding: 2px 8px;
  font-weight: 500;
  font-size: 2.3rem;
  background: #A5CCFF;
  margin:  2rem 1rem;
}


/* Headline */
.headline {
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  text-align: center;
}
.headline .title {
  margin: 12px 30px;
}
.icon {
  width: 160px;
  height: 160px;
}

/* Content Container */
.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 22px;
}
.contentContainer .title {
  margin: 20px 0 10px;
}
.contentContainer p {
  color: var(--color-text-gray500);
  font-size: 2rem;
  margin-bottom: 41px;
}
.innovationMindset .contentContainer:nth-of-type(3) img {
  display: none;
}
.innovationMindset .contentContainer:nth-of-type(3) h3 {
  margin-top: 0;
}


@media screen and (min-width: 768px) {
  /* General */
  .title {
    font-size: 3rem;
    line-height: 5rem;
  }
  .subtitle {
    font-size: 1.3rem;
  }

  /* Headline */
  .headline {
    flex-direction: row;
    padding-left: 50px;
    margin-bottom: 71px;
  }
  .headline div {
    padding-left: 26px;
    text-align: left;
  }
  .headline .title {
    margin-left: 0;
  }
  .headline .subtitle {
    margin-left: 0;
  }

  /* ContentContainer */
  .contentContainer {
    flex-direction: row;
    text-align: left;
  }
  .contentContainerLeft div, .contentContainerRight div {
    padding-right: 40px;
  }
  .contentContainer p {
    font-size: 2rem;
  }
  .contentContainer img {
    width: 252px;
    height: 252px;
  }
  .innovationMindset .contentContainer:nth-of-type(3) img {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  /* General */
  .title {
    font-size: 4rem;
    line-height: 6rem;
  }

  /* Headline */
  .headline .subtitle {
    width: 85%;
    text-align: center;
  }

  /* ContentContainer */
  .contentContainer .title {
    margin-bottom: 30px;
  }
  .contentContainer img {
    width: 350px;
    height: 350px;
  }
  .contentContainerLeft div {
    padding-right: 130px;
  }
  .contentContainerRight img {
    order: 1;
  }
  .contentContainerRight div {
    order: 2;
    padding-right: unset;
    padding-left: 37px;
  }
}

@media screen and (min-width: 1440px) {
  /* General */
  .innovationMindset {
    margin: 112px auto 0;
    width: 1110px;
  }
  .title {
    font-size: 4.4rem;
    line-height: 6rem;
  }

  /* Headline */
  .headline {
    padding-left: unset;
    width: 1110px;
    margin: auto;
  }
  .headline .subtitle {
    width: 100%;
    font-size: 1.8rem;
    text-align: center;
  }

  /* Content Container */
  .contentContainer {
    padding: 0;
    width: 1110px;
    margin: 50px auto;
  }
  .contentContainer img {
    width: 265px;
    height: 265px;
  }
  .contentContainerRight div {
    padding-left: 130px;
  }
}

@media print {
  .innovationMindsetOverview {
    display: none;
  }
}