.header {
  margin-top: 40px;
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 3.6rem;
  line-height: 5.4rem;
  font-weight: 600;
  color: var(--color-text-softBlack);
}

@media screen and (min-width: 768px) {
  .header {
    width: 642px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .EntreVsIntrapeneurs {
   display: flex;
   gap: 30px;
   margin-top: 35px;
  }
}

@media screen and (min-width: 1440px) {
  .header {
    width: unset;
  }
}

@media print {
  .containerStyles {
    display: none;
  }
}