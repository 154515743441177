@import url('../../styles/vars.css');

.ACTModelCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 19.4187px;
  gap: 23.46px;

  width: 260px;
  height: 336px;

  background: #FFFFFF;
  border: 1.41062px solid var(--color-border-gray);
  border-radius: 11.285px;
}

.triadName {
  font-size: 1.9rem;
  line-height: 3rem;
  letter-spacing: -0.02em;
}

.member {
  display: flex;
  align-items: center;
  gap: 17px;
}

.image {
  height: 58px;
  width: 58px;
}

.memberInfo h5 {
  font-size: 1.7rem;
  line-height: 2.25rem;
}

@media screen and (min-width: 768px) {
  .ACTModelCard {
    padding: 19.4187px 10px;
  }
}

@media screen and (min-width: 992px) {
  .ACTModelCard {
    gap: 23.46px;
    padding: 19.4187px;
    flex: 1;
  }
}

@media screen and (min-width: 1440px) {
  .ACTModelCard {
    height: 390px;
    padding: 24px;
    gap: 24px;
  }

  .triadName {
    font-size: 2.4rem;
    line-height: 3.6rem;
    letter-spacing: -0.02em;
  }

  .image {
    height: 72px;
    width: 72px;
  }

  .memberInfo h5, .memberInfo p {
    font-size: 2.1rem;
    line-height: 2.8rem;
  }
}