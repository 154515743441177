.hr {
  margin-top: 40px;
  overflow: hidden;
  border: 1px solid #DCDCDC;
}

@media screen and (min-width: 992px) {
  .linePatternLeft {
    transform: rotate(-90deg);
    position: absolute;
    z-index: -1;
    top: -45px;
    left: -100px;
  }

  .polygonLeft {
    position: absolute;
    z-index: -1;
    top: 80px;
    left: -75px;
  }
}

@media screen and (min-width: 1440px) {
  .linePatternRight {
    transform: rotate(-90deg);
    position: absolute;
    z-index: -1;
    top: 100px;
    right: -105px;
  }

  .polygonRight {
    transform: rotate(-90deg);
    position: absolute;
    z-index: -1;
    top: 260px;
    right: -90px;
  }
}