@import url('../../styles/vars.css');

/* Base Styles (>=375px) */
.resultsAreIn {
  /* Container Styles */
  background-color: rgb(246, 186, 19);
  margin-top: 20px;
  padding: 40px 24px 30px;
  border-radius: 8px;
  /* Container Text Styles */
  font-family: 'Inter', sans-serif;
  color: var(--color-text-softBlack);
}

.resultsAreIn p {
  font-family: 'Poppins', sans-serif;
}

.preheader {
  font-weight: 500;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.header {
  font-size: 2.7rem;
  margin-top: 18px;
}

.the_horns {
  display: inline-block;
  height: 42px;
  width: 42px;
  margin-left: 5px;
}

.questionContainer p {
  margin-top: 18px;
  font-size: 1.6rem;
  line-height: 24px;
}

.bullet {
  display: none;
}

/* Mobile Responsiveness */
@media screen and (min-width: 768px) {
  .resultsAreIn {
    display: flex;
    padding-top: 84px;
    padding-bottom: 42px;
    flex-direction: column;
    align-items: center;
  }

  .preheader {
    font-size: 2rem;
  }

  .header {
    font-size: 4.8rem;
  }

  .the_horns {
    height: 60px;
    width: 60px;
  }

  .questionContainer {
    width: 603px;
    margin-top: 18px;
    text-align: center;
  }

  .questionContainer > p {
    font-size: 2rem;
    display: inline;
  }

  .bullet {
    display: inline;
  }
}

@media screen and (min-width: 992px) {
  .resultsAreIn {
    margin-top: 43px;
    padding-bottom: 55px;
  }

  .preheader {
    font-size: 2.4rem;
  }

  .header {
    font-size: 7.2rem;
    letter-spacing: -0.02em;
  }

  .the_horns {
    height: 72px;
    width: 72px;
  }

  .questionContainer {
    width: 817px;
  }
}

@media screen and (min-width: 1440px) {
  .resultsAreIn {
    margin-top: 53px;
    padding-bottom: 150px;
    width: 1216px !important;
    max-width: 1216px !important;
  }

  .header {
    display: flex;
    align-items: center;
  }
}