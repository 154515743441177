@import url('../../styles/vars.css');

.SuperstarPairing {
  margin-top: 70px;
}

.header {
  text-align: center;
}

.header h2 {
  font-size: 3.6rem;
  line-height: 5.4rem;
  color: var(--color-text-black);
}

.header p {
  font-size: 2rem;
  line-height: 3rem;
  color: var(--color-text-gray500);

  margin-top: 24px;
}

@media screen and (min-width: 768px) {
  .header {
    text-align: left;
    margin-right: 85px;
  }
}

@media screen and (min-width: 992px) {
  .SuperstarPairing {
    margin-top: 110px;
  }

  .header {
    margin-right: 130px;
    max-width: 782px;
  }
}

@media screen and (min-width: 1440px) {
  .SuperstarPairing {
    margin-top: 62px;
  }
}

@media print {
  .SuperstarPairing {
    page-break-inside: avoid;
  }
}