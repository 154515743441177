.container {
  background-image: url('../../assets/images/michael-jordan-quote-image.png');
  background-position: 20% 0%;

  height: 625px;
  width: 100%;
  border-radius: 16px;
  margin-top: 58px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.quote {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(24px);
  padding: 28px 25px;
  border-radius: 0 0 16px 16px;
}

.text, .author {
  color: #fff;
  font-family: 'Poppins';
  text-align: center;
}

.text {
  font-size: 3rem;
  line-height: 4.4rem;
}

.author {
  font-style: italic;
  font-size: 2.4rem;
  line-height: 3.6rem;
  letter-spacing: -0.02em;
}

@media screen and (min-width: 768px) {
  .container {
    background-position: 0% 0%;
    height: 425px;
    margin-top: 29px;
  }

  .quote {
    height: 277px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .text {
    font-size: 3.6rem;
  }
}

@media screen and (min-width: 992px) {
  .container {
    background-position: 0% 25%;
    margin-top: 53px;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media print {
  .container {
    display: none;
  }
}