@import url('../../styles/vars.css');

.RiskRewardResponsibilityCards {
  margin-top: 48px;
}

.bold {
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  margin-top: 41px;
}

.grayText {
  color: var(--color-text-gray500);
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  margin-top: 32px;
}

@media screen and (min-width: 768px) {
  .cardContainer {
    padding-left: 0;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }

  .bold {
    margin-top: 54px;
    margin-right: 90px;
    text-align: left;
  }

  .grayText {
    text-align: left;
    margin-right: 90px;
  }
}

@media screen and (min-width: 992px) {
  .flexText {
    display: flex;
    gap: 65px;
  }

  .bold, .grayText {
    margin-top: 0;
    margin-right: 0;
  }
  
  .cardContainer {
    display: flex;
    gap: 18px;
    flex-wrap: nowrap;
  }
}

@media screen and (min-width: 1440px) {
  .cardContainer {
    gap: 32px;
  }
}