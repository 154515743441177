.CCC_Style_Cards {
  margin-top: 55px;
}

@media screen and (min-width: 768px) {
  .CCC_Style_Cards {
    display: flex;
    gap: 10px;
  }
}

@media screen and (min-width: 1440px) {
  .CCC_Style_Cards {
    gap: 16px;
  }
}