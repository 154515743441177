@import url('../../styles/vars.css');

.UnderstandingTheNarrative {
  margin-top: 154px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin-top: 70px;
  text-align: center;
  font-size: 3.6rem;
  line-height: 5.4rem;
  color: var(--color-text-softBlack);
}

.body {
  margin-top: 19px;
  font-size: 2rem;
  line-height: 3.4rem;
  text-align: center;
  color: var(--color-text-gray500);
}

.body * {
  font-family: 'Poppins', sans-serif;
}

.bold {
  font-weight: 700 !important;
}

.EncouragementTag {
  width: calc(100% - 16px);
  background-color: var(--color-overview-yellow);
  margin-top: 19px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}

.EncouragementTag span {
  font-size: 20px;
  line-height: 29px;
  font-weight: 500;
  color: var(--color-overview-text-lightBrown);
}

@media screen and (min-width: 768px) {
  .UnderstandingTheNarrative {
    position: relative;
    margin-top: 52px;
    flex-direction: row;
    align-items: center;
  }

  .UnderstandingTheNarrative img {
    position: absolute;
    right: 32px;
    top: 131px;
  }
  
  .header {
    margin-top: 0;
    text-align: left;
    max-width: 493px;
  }

  .body {
    max-width: 422px;
    text-align: left;
  }
}

@media screen and (min-width: 992px) {
  .UnderstandingTheNarrative {
    margin-top: 44px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .UnderstandingTheNarrative img {
    position: static;
    width: 370px;
  }

  .textContent {
    width: 50%;
  }
  
  .header {
    max-width: none;
  }

  .body {
    max-width: none;
    text-align: left;
  }
}