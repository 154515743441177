@import url('../../styles/vars.css');

.archetypeDiscoveryIcon {
  margin: 21.66px auto 0;
  width: 203px;
}

.header {
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 4.4rem;
  text-align: center;
  color: var(--color-text-gray900);
}

.description {
  font-size: 2rem;
  line-height: 3rem;
  color: var(--color-text-gray500);
  margin-top: 25px;
}

@media screen and (min-width: 768px) {
  .headerContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 59px;
  }

  .archetypeDiscoveryIcon {
    margin-right: 39px;
    margin-top: 20px;
  }
  
  .header {
    text-align: left;
  }

  .description {
    margin: 16px 0 0;
  }
}

@media screen and (min-width: 992px) {
  .headerContainer {
    margin-top: 82px;
  }

  .archetypeDiscoveryIcon {
    margin: 0 39px 0 0;
    width: 221px;
  }

  .headerTextContainer {
    max-width: 621px;
    margin-top: 10px;
  }

  .description {
    margin-right: 26px;
  }
}

@media screen and (min-width: 1440px) {
  .headerContainer {
    margin-top: 79px;
    height: 275px;
    overflow: hidden;
  }

  .archetypeDiscoveryIcon {
    margin-top: 13px;
    margin-right: 4px;
    width: 280px;
  }

  .headerTextContainer {
    margin-top: 45px;
  }
}

@media print {
  .headerContainer {
    display: none;
  }
}