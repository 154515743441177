@import url('../../styles/vars.css');

.CCC_Card {
  background: #FFFFFF;
  border: 1px solid var(--color-border-gray);
  border-radius: 8px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  height: 149px;
}

.header {
  color: var(--color-text-black);
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.5rem;

  margin-top: 13px;
}

.description {
  color: var(--color-text-gray500);
  font-family: 'Inter';
  font-size: 1.5rem;
  line-height: 1.65rem;
  text-align: center;
  
  margin-top: 13px;
}

@media screen and (min-width: 768px) {
  .CCC_Card {
    padding-top: 0;
  }

  .header {
    margin-top: 0;
  }
}

@media screen and (min-width: 992px) {
  .CCC_Card {
    padding-top: 20px;
  }

}

@media screen and (min-width: 1440px) {
  .icon {
    height: 180px;
  }
}