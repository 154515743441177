@import url('../../styles/vars.css');

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 49px;
}

.headerImg {
  height: 272px;
}

.textContainer {
  text-align: center;
}

.textContainer h2 {
  font-size: 3.6rem;
  line-height: 5.4rem;
  color: var(--color-text-black);

  margin-top: 22px;
}

.textContainer p {
  font-size: 2rem;
  line-height: 3rem;
  color: var(--color-text-gray500);

  margin-top: 24px;
}

@media screen and (min-width: 768px) {
  .headerContainer {
    flex-direction: row-reverse;
    gap: 18px;

    margin-top: 80px;
  }

  .textContainer {
    text-align: left;
  }
}

@media screen and (min-width: 992px) {
  .headerContainer {
    gap: 100px;
  }
}

@media screen and (min-width: 1440px) {
  .headerContainer {
    gap: 32px;
  }

  .headerImg {
    height: 366px;
    margin-right: 20px;
  }
}

@media print {
  .WellBalancedRecipe {
    display: none;
  }
}