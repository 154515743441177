@import url('./styles/reset.css');

/* Import Font families (all sans-serif):
  'Inter' - 400; 500; 600; 700;
  'Poppins' - 400; 400 italic; 500; 600; 700;
  'Montserrat' - 400; 700;
  'Open Sans' - 400; 600;
  'IBM Plex Sans' - 400; 700;
 */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&family=Inter:wght@400;500;600;700&family=Montserrat:wght@400;700&family=Open+Sans:wght@400;600&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 62.5%; /* 1rem = 10px */
}

body {
  font-size: 1.6rem;
  margin: 0;
  background: #F8FAFC;
}

/* Site-Wide Default Styles */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

p, span {
  font-family: 'Inter', sans-serif;
}

.App {
  overflow: hidden;
}

main {
  height: calc(100vh - 76px);
  width: 100vw;
  scroll-behavior: smooth;
  overflow-y: scroll;
  overflow-x: hidden;
}

.App > section {
  scroll-margin-top: 100px;
}

.swiper {
  padding-bottom: 50px !important;
}

.swiper-slide {
  width: 316px;
  display: flex;
}

.swiper-pagination-bullet  {
  height: 11px !important;
  width: 11px !important;
  margin: 0 6.5px !important;
}

@media screen and (min-width: 992px) {
  main {
    height: calc(100vh - 80px);
  }
}

@media print {
  * { 
    overflow: visible !important; 
  } 
}