.ArchetypeCards {
  display: flex;
  justify-content: space-between;
  padding: 0 3.5%;
}

.outlierPill {
  margin: 40px 3.5% 0;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  background: #FFE299;
  border-radius: 16px;
}

.outlierPill p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
}

@media screen and (min-width: 768px) {
  .ArchetypeCards {
    flex-direction: column;
    padding-left: 0;
  }
}

@media screen and (min-width: 1440px) {
  .ArchetypeCards {
    flex-direction: row;
    gap: 30px;
  }
}