@import '../../styles/vars.css';

.abilityContainer {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 14px;
}
.logoContainer {
  position: relative;
  width: 153px;
  height: 74px;
  background-image: url('./img/ability-mobile-bg.svg');
  background-size: 100%;
  background-position: 50% 10%;
  background-repeat: no-repeat;
  margin-bottom: 10px;
}
.logo {
  position: absolute;
  width: 73px;
  height: 70px;
  margin: auto;
  margin-top: 10px;
  object-fit: cover;
  left: 22.5%;
}
.subtitle {
  font-size: 2.7rem;
  font-weight: 600;
}

@media screen and (min-width: 768px) {
  .abilityContainer {
    padding: 0 80px;
  }
  .logoContainer {
    padding: 0;
    height: 87px;
    margin-bottom: 20px;
  }
  .logo {
    margin-top: 20px;
  }
  .subtitle {
    font-size: 3rem;
  }
}

@media screen and (min-width: 992px) {
  .abilityContainer {
    width: 90%;
    height: 358px;
    margin: auto;
    background-image: url('./img/ability-desktop-bg.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0 0;
  }
  .logoContainer {
    width: 75px;
    height: 70px;
    background-image: unset;
    margin-bottom: 0;
    margin-right: 20px;
  }
  .logo {
    width: unset;
    height: unset;
    left: 0;
    margin-top: 0;
  }
  .subtitle {
    font-size: 3rem;
    width: 633px;
    line-height: 4.2rem;
  }
}

@media screen and (min-width: 1440px) {
  .abilityContainer {
    width: 1237px;
    margin: auto;
    padding: 20px 0 0;
  }
  .logoContainer, .logo {
    width: 90px;
    height: 85px;
  }
  .subtitle {
    font-size: 3.6rem;
    line-height: 5.4rem;
    width: 860px;
    margin-top: 10px
  }
}

@media print {
  .abilityContainer {
    display: none;
  }
}