/* 
  To Use:
  import globalStyles from './global.module.css';

  In JSX: className={globalStyles['<class name from stylesheet>']}
*/

.text-sm-medium {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
}

.text-xl-normal {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
}

.container-width { /* Global rule to avoid redefining the rule for each section containers */
  width: 93%;
  margin-left: auto;
  margin-right: auto;
}

.relative {
  position: relative;
}

@media screen and (min-width: 768px) {
  .container-width {
    width: 94.7%;
  }
}

@media screen and (min-width: 992px) {
  .container-width {
    width: 91.33%;
    max-width: 1110px;
  }
}

@media screen and (min-width: 1440px) {
  .container-width {
    width: 1110px;
  }

  hr {
    max-width: 1110px;
  }
}