@import url('../../styles/vars.css');

.BlueCard {
  padding: 23px 22px;
  border-radius: 8px;
  background-color: var(--blue-card-shade);
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding: 17px 21px 0.5rem;
  width: 25rem;

  /* Rounded right side */
  border-top-right-radius: 9999px; 
  border-bottom-right-radius: 9999px; 
}

.heading {
  font-size: 2rem;
}

.subheading {
  font-size: 1.3rem;
}

.foreground {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(21.6px);
}

.foreground > h4 {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 2.4rem;
}

.foreground > p {
  font-family: 'Poppins';
}

.body {
  padding: 0 21px 22px;
}