@import '../../styles/vars.css';

a, p, span {
  font-family: 'Poppins', sans-serif;
}
nav {
  box-shadow: 0px 4px 4px 0px #8F8F8F1A;
  width: 100%;
  background-color: #000000;
}
.navbarMain {
  height: 76px;
}

.navbarMainActive .listContainer {
  display: block;
  position: absolute;
  bottom: 0;
  height: calc(100% - 76px);
  width: 100vw;
  z-index: 999;
  margin-top: 0;
  opacity: 1;
}

.listContainer {
  background-color: #000000;
  height: 0%;
  overflow: hidden;
  z-index: -1;
  opacity: 0;
  margin-top: -25px;
  transition: all 0.4s ease-in-out;
}

.navbarMain button, .navbarMainActive button {
  background: none;
  border: none;
}
.navTextActive {
  transition: all 0.4s ease;
}
.navTextActive a {
  color: #FF0000 !important;
  padding-bottom: 2rem;
  border-bottom: 4px solid #FF0000;
}
.mobileContainer {
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 18px 24px 18px 24px;
}
.logoLink {
  height: 100%;
  flex: 0 0 60%;
}
.logo {
  height: 100%;
  object-fit: contain;
}
.hamburgerContainer {
  flex: 0 0 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hamburger {
  width: 18px;
  height: 12px;
}

.contentContainer {
  width: 100%;
}
.contentContainer ul {
  list-style-type: none;
  text-align: center;
  padding-left: 0;
}
.contentContainer li {
  line-height: 15vh;
}
.contentContainer a {
  color: #ffffff;
  text-decoration: none;
  font-size: 2rem;
}
.socialContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.socialContainer span {
  color: var(--color-text-gray500);
  margin-bottom: 15px;
}
.socialContainer ul {
  list-style-type: none;
  padding-left: 0;
}
.socialContainer li {
  float: left;
  margin: 0;
}

.insta {
  width: 50px;
  height: 50px;
}

@media screen and (min-width: 768px) {
  .logoLink {
    flex: 0 0 30%;
  }
  .socialContainer span {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 992px) {
  .navbarMain {
    height: 80px;
  }
  .mobileContainer {
    height: 80px;
  }
}

@media screen and (min-width: 1440px) {
  nav {
    background-color: #ffffff;
  }
  .mobileContainer {
    width: 1216px;
    margin: auto;
  }
  .navTextActive {
    padding-bottom: 2rem;
  }
  .contentContainer {
    width: unset;
  }
  .contentContainer ul {
    height: 80px;
  }
  .contentContainer a {
    color: var(--color-text-gray500);
    font-size: 1.4rem;
  }
  .contentContainer li {
    float: left;
    margin: 0 15px;
    line-height: 80px;
  }
  .socialContainer {
    flex-direction: row;
  }
  .socialContainer button {
    width: 48px;
    height: 48px;
  }
  .socialContainer li {
    margin: 0;
  }
  .socialContainer span {
    margin-bottom: unset;
    font-size: 1.3rem;
  }
  .insta {
    width: 38px;
    height: 38px;
  }
}

@media print {
  nav {
    display: none;
  }
}