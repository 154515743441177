@import url('../../styles/vars.css');

.CCC_Styles {
  margin-top: 56px;
}

.headerContainer {
  text-align: center;
}

.header {
  font-size: 3.6rem;
  line-height: 5.4rem;
  color: var(--color-text-black);
}

.subheader {
  font-size: 2rem;
  line-height: 3rem;
  color: var(--color-text-gray500);

  margin-top: 24px;
}


.reminderChip {
  background-color: var(--color-cohesion-darkCyan);
  border-radius: 16px;

  max-width: 634px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  margin-top: 34px;
}

.reminderChip p {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.7rem;
  text-align: left;
}

@media screen and (min-width: 768px) {
  .headerContainer {
    text-align: left;
  }

  .reminderChip {
    margin-top: 87px;
  }
}

@media screen and (min-width: 992px) {
  .reminderChip {
    display: inline-block;
    max-width: unset;
    margin-top: 60px;
  }
}

@media print {
  .subheader, .reminderChip {
    display: none;
  }
}