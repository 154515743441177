@import url('../../styles/vars.css');

.ACTModelExamples {
  margin-top: 71px;
}

.header, .subheader {
  text-align: center;
}

.header {
  font-size: 3.6rem;
  line-height: 5.4rem;
  color: var(--color-text-black);
}

.subheader {
  font-size: 2rem;
  line-height: 3rem;
  color: var(--color-text-gray500);

  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  .ACTModelExamples {
    margin-top: 26px;
  }

  .header, .subheader {
    text-align: left;
  }

  .header {
    max-width: 802px;
  }

  .subheader {
    max-width: 750px;
    margin-top: 24px;
  }
}

@media screen and (min-width: 1440px) {
  .ACTModelExamples {
    margin-top: 36px;
  }
}

@media print {
  .ACTModelExamples {
    display: none;
  }
}