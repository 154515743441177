@import url('../../styles/vars.css');

.ResultsError {
  border: 1px solid var(--color-border-gray);
  border-radius: 8px;
  background-color: white;
  width: fit-content;
  margin: 40px auto;
  padding: 30px 45px;
}

.ResultsError h4 {
  text-align: center;
  color: var(--color-red);
  font-size: 2.4rem;
  font-weight: 500;
}