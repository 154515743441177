@import url('../../styles/vars.css');

.circlesCard {
  border: 1px solid var(--color-border-gray);
  border-radius: 16px;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.circlesCard h6 {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: var(--color-text-charcoal);
}

.circlesCard1 {
  margin-top: 24px;
  padding: 24px 36px 45px;
}

.circlesCard p {
  margin-top: 15px;
  line-height: 2.6rem;
  color: var(--color-text-gray500);
}

.circlesCard2 {
  margin-top: 15px;
  padding: 35px 12px;
}

.circle {
  margin-top: 40px;
}

@media screen and (min-width: 768px) {
  .circlesCard h6 {
    font-weight: 700;
    font-size: 2rem;
  }

  .circlesCard1 {
    height: 279px;
    flex-direction: row;
    align-items: center;
    padding: 0 100px 0 45px;
  }

  .circlesCard1 .circle {
    margin-top: 0;
    margin-right: 56px;
  }

  .circlesCard2 {
    padding: 21px 33px 60px;
  }

  .circlesCard2 .circleInnerContainer,
  .circlesCard2 .textContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .circlesCard2 .textContainer {
    gap: 38px;
    margin-top: 39px;
  }

  .circlesCard2 .textContainer p {
    flex: 1;
  }
}

@media screen and (min-width: 992px) {
  .circleParentContainer {
    background-color: white;
    border: 1px solid var(--color-border-gray);
    border-radius: 16px;
    display: flex;
    padding: 35px 35px 40px;
    margin-top: 52px;
  }

  .circlesCard {
    border: unset;
    border-radius: unset;
    margin-top: 0;
    padding: 0;
  }

  .circlesCard h6 {
    font-size: 1.6rem;
  }

  .circlesCard p {
    margin-top: 0;
  }

  .circlesCard1 {
    flex: 0 0 222px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 16px 16px 0 0;
    margin-right: 38px;
  }

  .circlesCard1 .circle {
    margin-top: 40px;
  }

  .circlesCard2 {
    border-radius: 0 0 16px 16px;
  }

  .circlesCard1 p,
  .circlesCard2 .textContainer {
    margin-top: 70px;
  }

  .circlesCard2 .circleInnerContainer {
    max-width: 600px;
  }
}

@media screen and (min-width: 1440px) {
  .circlesCard1 {
    align-items: center;
    flex: 0 0 285px;
  }

  .circlesCard1 .circle {
    margin-right: 0;
  }

  .circlesCard2 .textContainer {
    gap: 63px;
  }
}