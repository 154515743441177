@import url('../../styles/vars.css');

.GravitationalIdeologyCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 11px;
  padding: 24px 16px;
  gap: 8px;
  background: #FFFFFF;
  border: 1px solid var(--color-border-gray);
  border-radius: 8px;
  color: var(--color-text-softBlack);
}

.preheader {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 3.8rem;
  text-transform: uppercase;
  margin-top: 7.5px;
}

.header {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 2.4rem;
  margin-top: 8px;
  letter-spacing: -1px;
}

.body {
  margin-top: 23.5px;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: var(--color-text-gray500);
}

.bold {
  font-weight: 600;
}

.ThoughtLabels {
  color: var(--color-text-softBlack);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 42px;
}

.ThoughtLabel {
  background-color: var(--color-overview-yellow);
  display: inline-flex;
  width: fit-content;
  min-width: 204px;
  max-width: 261px;
  align-items: center;
  justify-content: center;
  padding: 8px 14px;
  border-radius: 8px;
}

.ThoughtLabel img {
  fill: black;
  margin-right: 8px;
}

.ThoughtLabel:not(:first-of-type) {
  margin-top: 8px;
}

@media screen and (min-width: 768px) {
  .GravitationalIdeologyCard {
    align-items: center;
    margin-top: 29px;
    padding: 48px 5px 36.5px;
  }

  .preheader {
    font-size: 2rem;
    margin-top: 0px;
  }

  .header {
    font-size: 3.2rem;
    text-align: center;
    margin-top: 8px;
  }

  .body {
    width: 642px;
    margin-top: 16px;
    text-align: center;
  }

  .ThoughtLabels {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 556px;
    margin-top: 10px;
  }

  .ThoughtLabel:not(:first-of-type) {
    margin-top: 0px;
  }

  .ThoughtLabel:last-of-type {
    max-width: none;
    width: 556px;
    margin-top: 10px;
  }

  .ThoughtLabel:not(:last-of-type) {
    width: 239px;
    margin: 0 5px;
  }
}

@media screen and (min-width: 1440px) {
  .GravitationalIdeologyCard {
    width: 728px;
    position: absolute;
    left: 0;
    top: -125px;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 62px;
  }

  .preheader {
    font-size: 2.4rem;
  }

  .header {
    width: 591px;
    line-height: 2.9rem;
  }

  .body {
    width: 100%;
    margin-top: 8px;
  }

  .ThoughtLabels {
    margin-top: 39px;
  }

  .CurvedArrow {
    width: 64px;
    height: 64px;
    position: absolute;
    right: 47.49px;
    bottom: 130.68px;
  }
}