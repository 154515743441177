@import url('../../styles/vars.css');

.SkillsTalentsRankCards {
  margin-top: 14px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19.1895px 19.1895px 19.1895px 17.5904px;
  gap: 8px;
  background: #FFFFFF;
  border: 0.719606px solid var(--color-border-gray);
  border-radius: 6.3965px;
}

.card:not(:first-of-type) {
  margin-top: 21.46px;
}

.header {
  font-weight: 700;
  font-size: 1.91895rem;
  line-height: 1.9rem;
}

.body {
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  color: var(--color-text-gray500);
}

@media screen and (min-width: 768px) {
  .SkillsTalentsRankCards {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .card:not(:first-of-type) {
    margin-top: 0;
  }

  .card {
    width: 222px;
    align-items: flex-start;
    margin-right: 14px;
  }

  .card:nth-of-type(2) {
    width: 255px;
  }

  .body {
    font-size: 1.2793rem;
    line-height: 1.9rem;
  }
}

@media screen and (min-width: 992px) {
  .SkillsTalentsRankCards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }

  .card {
    width: 278px;
    align-items: flex-start;
    margin-right: 14px;
    padding: 24px;
  }

  .card:nth-of-type(2) {
    width: 319px;
  }

  .header {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }

  .body {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
}

@media screen and (min-width: 1440px) {
  .SkillsTalentsRankCards {
    gap: 28px;
  }

  .card, .card:nth-of-type(2) {
    width: 351px;
  }
}