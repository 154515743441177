@import url('../../styles/vars.css');

.GravitateCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--color-overview-yellow);
  color: var(--color-text-softBlack);
  padding: 24px;
  margin-top: 33px;
  border-radius: 8px;
}

.header {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.6rem;
  margin-top: 7px;
}

.body {
  margin-top: 7px;
  font-size: 1.4rem;
  line-height: 2.1rem;
}

@media screen and (min-width: 768px) {
  .GravitateCard {
    flex: 2;
  }
}

@media screen and (min-width: 1440px) {
  .GravitateCard {
    position: absolute;
    right: 0px;
    top: 80px;
    height: 340px;
    width: 350px;
  }

  .header {
    margin-top: 24px;
    color: var(--color-overview-text-brown);
    font-size: 2rem;
  }
  
  .body{
    margin-top: 24px;
    font-size: 1.6rem;
    color: var(--color-overview-text-brown);
  }
}