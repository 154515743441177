@import url('../../styles/vars.css');

.RiskRewardResponsibilityCard {
  background: #FFFFFF;
  border: 1px solid var(--color-border-gray);
  border-radius: 8px;
  width: 285px;
  padding-top: 20px;
  padding-bottom: 19px;
  padding-left: 24px;
}

.topRow {
  display: flex;
  justify-content: space-between;
}

.icon {
  margin-left: 10px;
}

.scoreContainer {
  width: 137px;
  height: 67px;
  background-color: var(--color-innovation-skyBlue);
  border-radius: 100px 0 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scoreContainer span {
  color: var(--color-innovation-text-blue);
}

.scoreContainer span:first-child {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.7rem;
}

.score {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.9rem;
}

.header {
  color: var(--color-text-black);
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
}

.body {
  margin: 16px 20px 0 0;
}

.description {
  margin-top: 7px;
  margin-right: 15px;

  color: var(--color-text-gray500);
  font-family: 'Inter';
  font-size: 1.4rem;
  line-height: 2rem;
}

@media screen and (min-width: 768px) {
  .RiskRewardResponsibilityCard {
    width: calc(50% - 15px);
    margin-right: 0;
    margin-top: 30px;
    height: auto;
  }

  .icon {
    margin-left: 10px;
  }

  .RiskRewardResponsibilityCard {
    padding-bottom: 31px;
  }

  .RiskRewardResponsibilityCard:last-child {
    width: 100%;
    margin-top: 0px;
  }
}

@media screen and (min-width: 992px) {
  .RiskRewardResponsibilityCard {
    margin-top: 45px;
    padding-bottom: 12px;
    width: auto;
    min-width: unset;
    height: 316px;
  }

  .description {
    margin-top: 7px;
  
    color: var(--color-text-gray500);
    font-family: 'Inter';
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .RiskRewardResponsibilityCard:last-child {
    margin-top: 45px;
    width: auto;
  }

  .bold {
    font-weight: 700;
  }
}

@media screen and (min-width: 1440px) {
  .RiskRewardResponsibilityCard {
    padding-bottom: 12px;
    height: 289px;
  }
}

@media print {
  .RiskRewardResponsibilityCard {
    page-break-inside: avoid;
  }
}