@import url('../../styles/vars.css');

.shareModal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(196, 196, 196, .7);
}

.shareModalCard {
  position: relative;
  border: 1px solid var(--color-border-gray);
  border-radius: 16px;
  background-color: white;
  padding: 30px 24px;
  width: 315px;
  z-index: 1000;
}

.xContainer {
  position: absolute;
  top: 12px;
  right: 14px;
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.xSymbol {
  height: 14px;
  width: 14px;
}

.shareHeader {
  font-family: 'Poppins';
  font-size: 1.9rem;
}

.shareTextarea {
  background-color: var(--color-border-gray);
  margin-top: 15px;
  padding: 8px;
  border-radius: 8px;
}

.shareTextarea p {
  color: var(--color-text-gray700);
}

.shareModalCard .closeButton {
  margin-top: 15px;
  margin-left: auto;
  height: 40px;
  width: 75px;
  border: none;
  background-color: var(--color-innovation-text-lightBlue);
  color: white;
  font-size: 1.8rem;
}