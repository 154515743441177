.SuperstarPairingCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  padding: 40px 16px;
  margin-top: 58px;
  background: #FFFFFF;
  border: 0.83213px solid var(--color-border-gray);
  border-radius: 6.65704px;
}

.header {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3.6rem;
  text-align: center;

  margin: 26.25px 35.5px 0;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19px;
}

.userArchetypes {
  padding: 13px 0;
}

.archetype {
  display: flex;
  align-items: center;
  gap: 10px;
}

.imageContainer {
  width: 100px;
  display: flex;
  justify-content: center;
}

.dominantArchetype .imageContainer {
  height: 100px;
}

.subdominantArchetype .imageContainer {
  height: 70px;
}

.dominantArchetype .archetypeImage {
  height: 100px;
  width: 100px;
}

.subdominantArchetype .archetypeImage {
  height: 70px;
  width: 70px;
}

.subdominantArchetype {
  margin-top: -7.5px;
}

.archetypeText h4 {
  font-size: 1.5rem;
  line-height: 2.2rem;
  color: var(--color-text-black)
}

.archetypeText p {
  font-size: 1.16rem;
  line-height: 1.7rem;
  color: var(--color-text-black)
}

.lineContainer {
  margin-top: 13px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashedLine {
  transform: rotate(90deg);
}

.matchingArchetypes {
  display: flex;
  gap: 13.5px;
}

.matchingArchetype {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.archetypeMatchImage {
  height: 133.5px;
  width: 133.5px;
}

.archetypeMatchText {
  margin-top: 13.3px;
}

@media screen and (min-width: 768px) {
  .contentContainer {
    flex-direction: row;
    gap: 25px;
    width: 100%;
  }

  .archetypeText h4 {
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: var(--color-text-black)
  }
  
  .archetypeText p {
    font-size: 1.3rem;
    line-height: 1.7rem;
    color: var(--color-text-black)
  }

  .dashedLine {
    transform: rotate(0deg);
  }
}

@media screen and (min-width: 992px) {
  .SuperstarPairingCard {
    padding-bottom: 60px;
  }

  .contentContainer {
    margin-top: 43px;
    gap: 45px;
  }

  .header {
    margin-top: 4px;
  }
}

@media screen and (min-width: 1440px) {
  .contentContainer {
    gap: 80px;
  }

  .archetypeMatchImage {
    height: 160px;
    width: 160px;
  }

  .archetype {
    gap: 15px;
  }

  .imageContainer {
    width: 118px;
    display: flex;
    justify-content: center;
  }
  
  .dominantArchetype .imageContainer {
    height: 118px;
  }
  
  .subdominantArchetype .imageContainer {
    height: 83px;
  }
  
  .dominantArchetype .archetypeImage {
    height: 118px;
    width: 118px;
  }
  
  .subdominantArchetype .archetypeImage {
    height: 83px;
    width: 83px;
  }
  
  .subdominantArchetype {
    margin-top: -10px;
  }
}