@import '../../styles/vars.css';

/* General */
.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  padding: 0 15px;
}
.content {
  order: 1;
}
.content h3 {
  font-size: 4rem;
  line-height: 6rem;
}
.content p {
  margin: 25px 0;
  color: var(--color-text-gray500);
  font-size: 2.1rem;
}
.iconContainer {
  padding: 0 30px;
}
@media screen and (min-width: 768px) {
  .contentContainer {
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
    padding: 0;
  }
  .contentContainer h3 {
    order: 0;
    flex: 0 0 65%;
    font-size: 4rem;
    line-height: 6rem;
  }
  .content {
    order: 2;
    flex: 0 0 100%;
  }
  .iconContainer {
    order: 1;
    padding: unset;
    margin-right: 5%;
    flex: 0 0 30%;
  }
}
@media screen and (min-width: 992px) {
  .contentContainer {
    margin: auto;
  }
  .content {
    order: 0;
    flex: 0 0 45%;
  }
  .iconContainer {
    min-width: 350px;
    height: 350px;
  }
  .iconContainer img {
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .content {
    flex: 0 0 57.5%;
  }
}

@media print {
  .contentContainer {
    display: none;
  }
}