@import url('../../../styles/vars.css');

.CharacteristicCard {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.innerCard {
  border: 1px solid var(--color-border-gray);
  border-radius: 8px;

  display: flex;
  flex-direction: column;

  color: var(--color-text-gray500);
}

.topCard {
  padding: 24px 16px;
  gap: 16px;
}

.topCard h3 {
  font-size: 1.8rem;
  line-height: 2rem;
}

.icon {
  width: 42px;
  height: 42px;
  margin: 5px;
}

.bottomCard {
  padding: 16px;
  gap: 12px;
}

.bottomCard h3 {
  font-size: 2rem;
  line-height: 2rem;
}

.bottomCard p {
  font-size: 1.4rem;
  line-height: 2rem;
}

@media screen and (min-width: 768px) {
  .CharacteristicCard {
    flex: 1;
  }

  .topCard {
    height: 345px;
  }

  .bottomCard {
    height: 227px;
  }
}

@media screen and (min-width: 992px) {
  .CharacteristicCard {
    gap: 24px;
  }

  .topCard {
    height: 304px;
  }

  .bottomCard {
    height: 194px;
  }
}

@media screen and (min-width: 1440px) {
  .CharacteristicCard {
    gap: 32px;
  }

  .topCard {
    height: 257px;
  }

  .bottomCard {
    height: 160px;
  }
}

@media print {
  .CharacteristicCard {
    page-break-inside: avoid;
  }
}