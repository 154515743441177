.ProgressCircleChildren {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProgressCircleChildren h2, .ProgressCircleChildren h4 {
  font-family: 'Montserrat', sans-serif;
}

.ProgressCircleChildren h2 {
  font-weight: 700;
  font-size: 4rem;
  line-height: 4.8rem;
}

.ProgressCircleChildren h4 {
  font-weight: 400;
  font-size: 2.6rem;
  line-height: 3.2rem;
  letter-spacing: -0.02em;
}

.ProgressCircleTooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoIcon {
  width: 15px;
  height: 15px;
}

.ProgressCircleChildren .tooltipText {
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  letter-spacing: -0.02em;
  color: #F6F6F6;

  margin: 0 auto;
  max-width: 211px;
}