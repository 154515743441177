@import '../../styles/vars.css';

/* General */
.innovationMindset {
  margin-top: 148px;
}
hr {
  margin: 60px auto;
  border: 1px solid #DCDCDC;
  width: 100%;
}

.hidden320 {
  display: none;
}

@media (min-width: 768px) {
  .hidden320 {
    display: block;
  }
}

@media screen and (min-width: 768px) {

  /* ContentContainer */
  .innovationMindset .contentContainer:nth-of-type(3) img {
    display: block;
  }
}

@media screen and (min-width: 992px) {

}

@media screen and (min-width: 1440px) {
  .innovationMindset {
    margin: 112px auto 0;
  }
}

@media print {
  hr {
    display: none;
  }
}