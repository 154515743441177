.hr {
  display:none;
}

.flexCards {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .flexCards {
    flex-direction: row-reverse;
  }

  .LinePatternRight {
    position: absolute;
    right: 0;
    top: 580px;
    z-index: -1;
  }

  .hr {
    display:block;
    width: 100vw;
    border: 1px solid #DCDCDC;
    margin-top: 70px;
  }
}

@media screen and (min-width: 992px) {
  .LinePatternRight {
    top: 620px;
  }
}

@media screen and (min-width: 1440px) {
  .gravitational-ideology {
    position: relative;
    padding-bottom: 536px;
  }

  .AtomIllustration {
    position: absolute;
    right: 80px;
    top: -100px;
  }

  .LinePatternRight {
    top: 140px;
    right: -100px;
  }

  .LinePatternLeft {
    position: absolute;
    top: -270px;
    left: -210px;
    z-index: -1;
  }

  .hr {
    display: none;
  }
}