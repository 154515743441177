.CharacteristicCardContainer {
  margin-top: 27px;
}

@media screen and (min-width: 768px) {
  .CharacteristicCardContainer {
    margin-top: 74px;
    display: flex;
    gap: 6px;
  }
}

@media screen and (min-width: 992px) {
  .CharacteristicCardContainer {
    gap: 28px;
  }
}

@media screen and (min-width: 1440px) {
  .CharacteristicCardContainer {
    margin-top: 32px;
    gap: 30px;
  }
}